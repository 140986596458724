import { FC, useMemo } from 'react';
import { useQueries, QueryFunctionContext } from 'react-query';

import styles from './ResourceTeamsList.module.scss';

import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { useGetTeamsSymbolImage } from 'context/TeamsContext/hooks/useGetTeamsSymbolImage';
import { constants } from 'globalConstants';
import { useTeamsService, FetchTeamOptions } from 'services/TeamsService/useTeamsService';
import { Queries } from 'types/enums/Queries';
import { TagNames } from 'types/enums/Tags';
import { IAsset } from 'types/interfaces';
import { IGetTeamResponseItem } from 'types/interfaces/Teams/ITeam';
import { TeamSortBy } from 'types/interfaces/Teams/TeamSorting';

interface Props {
  asset?: IAsset;
}

const onTeamClick = (teamId: string) => {
  const { teamsPortal } = constants.routes;
  window.open(`/${teamsPortal.BASE_ROUTE}/${teamsPortal.PORTAL_TEAMS}/${teamId}`, '_blank');
};

export const ResourceTeamsList: FC<Props> = ({ asset }) => {
  const { fetchTeams } = useTeamsService();
  const teamsNames = (asset?.tags || []).reduce((acc, tag) => {
    if (tag.name === TagNames.Teams) {
      acc.push(tag.value);
    }
    return acc;
  }, [] as string[]);

  const queries = teamsNames.map((teamName) => ({
    queryKey: [Queries.Teams, {
      searchValue: teamName,
      displayImage: true,
    }],
    queryFn: ({ queryKey }: QueryFunctionContext) => fetchTeams({ queryKey } as QueryFunctionContext<[string, FetchTeamOptions]>),
  }));

  const teamQueries = useQueries(queries);
  const teams = useMemo(
    () => teamQueries.reduce<IGetTeamResponseItem[]>((acc, query) => {
      if (query.data?.data) {
        acc.push(...query.data.data);
      }
      return acc;
    }, []),
    [teamQueries],
  );

  const { symbolImages } = useGetTeamsSymbolImage(teams);

  return (
    <div className={styles.wrapper}>
      {teams.map((team, index) => (
        <JitTooltip
          key={team.id}
          followCursor={false}
          offset={[10, 0]}
          placement='top'
          title='pages.risks.resources.resourcePage.resourceDetails.goToTeamPage'
        >
          <div className={styles.teamWrapper} onClick={() => onTeamClick(team.id)} role='button' tabIndex={0}>
            <img alt='team icon' src={symbolImages[index]?.src} width={28} />

            <JitText horizontalAlign='center' text={team[TeamSortBy.NAME]} />
          </div>
        </JitTooltip>
      ))}
    </div>
  );
};
