import { SxProps } from '@mui/material';
import Input from '@mui/material/Input';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { JitTooltip } from '../JitTooltip/JitTooltip';

import { InputErrorIndicator } from './InputErrorIndicator';
import styles from './JitTextInput.module.scss';

import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { i18n } from 'locale/i18n';
import colors from 'themes/colors.module.scss';
import { IChangeInputEvent, ISvg } from 'types/interfaces';
import { useDebounce, useUpdateEffect } from 'utils';

export type JitTextInputVariants = 'default' | 'light' | 'dark' | 'bright' | 'dimBlue';
export type JitTextInputIconPosition = 'start' | 'end';

type VariantsStyle = {
  borderColor?: string;
  iconColor?: string;
  placeholder?: string;
  textColor?: string;
  borderRadius?: string;
  focusedIconColor?: string;
  focusedBorderColor?: string;
  backgroundColor?: string;
  height?: string;
  borderWidth?: string;
  labelColor?: string;
  hoveredBorderColor?: string;
};

const variantsStyles: Record<JitTextInputVariants, VariantsStyle> = {
  default: {
    borderColor: colors.white,
    iconColor: colors.white,
    placeholder: colors.lightGray,
    textColor: colors.lightGray,
    borderRadius: '4px',
    focusedIconColor: colors.iris,
    focusedBorderColor: colors.iris,
    borderWidth: '1px',
  },
  light: {
    borderColor: colors.white,
    iconColor: colors.white,
    placeholder: colors.lightGray,
    textColor: colors.lightGray,
    borderRadius: '4px',
    borderWidth: '1px',
  },
  dark: {
    borderColor: colors.darkGray,
    iconColor: colors.darkGray,
    placeholder: colors.lightGray,
    textColor: colors.gray,
    borderWidth: '1px',
  },
  bright: {
    borderColor: colors.white,
    textColor: colors.cards,
    placeholder: colors.gray,
    borderRadius: '8px',
    focusedBorderColor: colors.jitPink,
    hoveredBorderColor: colors.jitPink02,
    backgroundColor: colors.white,
    height: '50px',
    borderWidth: '2px',
    labelColor: colors.gray,
  },
  dimBlue: {
    textColor: colors.white,
    borderColor: colors.white,
    borderRadius: '4px',
    backgroundColor: colors.cardContent,
    height: '40px',
    borderWidth: '1px',
  },
};

interface Props {
  onChange: (value: string) => void;
  defaultValue?: string;
  placeholder?: string;
  fullWidth?: boolean;
  disableUnderline?: boolean;
  label?: string;
  icon?: FC<ISvg>
  iconPosition?: JitTextInputIconPosition;
  iconTooltip?: string;
  debounceDelay?: number;
  variant?: JitTextInputVariants
  maxRows?: number;
  rows?: number;
  multiline?: boolean;
  value?: string;
  isError?: boolean;
  isRequired?: boolean;
  overrideStyles?: SxProps;
  'data-testid'?: string;
  inputDataTestId?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  boldLabel?: boolean;
  onErrorDisplayedMessage?: string;
}

export const JitTextInput: FC<Props> = ({
  onChange, label, defaultValue, placeholder, debounceDelay = 0, isError, isRequired, overrideStyles = {},
  'data-testid': dataTestId = 'jit-text-input',
  fullWidth = true, disableUnderline = true, icon, iconPosition = 'start', iconTooltip, variant = 'default', maxRows, rows, multiline, value,
  inputDataTestId = 'jit-text-input-element', disabled, autoFocus, boldLabel, onErrorDisplayedMessage,
}) => {
  const { t } = i18n;
  const [innerValue, setInnerValue] = useState<string>(value || defaultValue || '');
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const { debounceValue } = useDebounce(innerValue, debounceDelay);

  const handleChange = (e: IChangeInputEvent) => {
    const { value: val } = e.target;
    setInnerValue(val);
  };

  useUpdateEffect(() => {
    if (value !== innerValue) setInnerValue(value || defaultValue || '');
  }, [value]);

  useEffect(() => {
    onChange(debounceValue);
  }, [debounceValue, onChange]);

  const placeholderText = placeholder ? t(placeholder) : undefined;

  const variantStyle = variantsStyles[variant];

  const getIconComponent = useCallback(() => {
    const iconComponent = (
      <JitIcon
        color={isFocused ? variantStyle.focusedIconColor : variantStyle.iconColor}
        icon={icon}
        iconClassName={styles.icon}
      />
    );
    if (iconTooltip) {
      return (<JitTooltip followCursor={false} placement='left-start' title={iconTooltip}>{iconComponent}</JitTooltip>);
    }
    return iconComponent;
  }, [icon, iconTooltip, isFocused, variantStyle.focusedIconColor, variantStyle.iconColor]);

  const displayError = isError && value;

  const startAdornment = iconPosition === 'start' && icon && getIconComponent();

  const endAdornment = useMemo(() => {
    if (displayError) {
      return <InputErrorIndicator errorMessage={onErrorDisplayedMessage} variant={variant} />;
    }

    return iconPosition === 'end' && icon && getIconComponent();
  }, [displayError, onErrorDisplayedMessage, getIconComponent, icon, iconPosition, variant]);

  return (
    <div data-testid={dataTestId}>
      {label && (
        <div className={styles.labelWrapper}>
          <JitText bold={boldLabel} color={variantStyle.labelColor || colors.lightGray} text={label} />
        </div>
      )}

      <Input
        autoFocus={autoFocus}
        className={styles.inputPlaceHolder}
        disabled={disabled}
        disableUnderline={disableUnderline}
        endAdornment={endAdornment}
        error={isError}
        fullWidth={fullWidth}
        inputProps={{
          'data-testid': inputDataTestId,
        }}
        maxRows={maxRows}
        multiline={multiline}
        onBlur={() => { setIsFocused(false); }}
        onChange={handleChange}
        onFocus={() => { setIsFocused(true); }}
        placeholder={placeholderText}
        required={isRequired}
        rows={rows}
        startAdornment={startAdornment}
        sx={{
          '&.Mui-focused': {
            borderColor: displayError ? colors.failRed : variantStyle.focusedBorderColor,
          },
          '&:hover': {
            borderColor: displayError ? colors.failRed : variantStyle.hoveredBorderColor,
          },
          '&.Mui-focused:hover': { // This will ensure the focused color is shown even on hover
            borderColor: displayError ? colors.failRed : variantStyle.focusedBorderColor,
          },
          color: innerValue ? variantStyle.textColor : variantStyle.placeholder,
          border: variantStyle.borderWidth ? `${variantStyle.borderWidth} solid ${variantStyle.borderColor}` : undefined,
          padding: multiline ? '4px 8px' : '0 8px',
          fontSize: '14px',
          borderRadius: variantStyle.borderRadius,
          backgroundColor: variantStyle.backgroundColor,
          height: variantStyle.height,
          borderColor: displayError ? colors.failRed : variantStyle.borderColor,
          ...overrideStyles,
        }}
        value={innerValue}
      />
    </div>
  );
};
