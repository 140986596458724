import { FC } from 'react';

import styles from './Results.module.scss';

import { JitChip } from 'components/JitChip/JitChip';
import { JitText } from 'components/JitText/JitText';
import { TooltipOnlyOnOverflow } from 'components/TooltipOnlyOnOverflow/TooltipOnlyOnOverflow';
import { i18n } from 'locale/i18n';
import colors from 'themes/colors.module.scss';
import { ITableCell } from 'types/interfaces';

export const ResultsCell: FC<ITableCell> = ({ cell: { value } }) => {
  const text = `${value.findingsCount} ${i18n.t('pages.teamsPortal.resourcePage.columns.securityFindings', { findingsCount: value.findingsCount })}`;
  return (
    <div className={styles.wrapper}>
      <JitChip
        edgesShape='rectangle'
        success={value.passed}
        text={value.passed
          ? 'pages.teamsPortal.resourcePage.columns.passed'
          : 'pages.teamsPortal.resourcePage.columns.failed'}
        variant='filled'
      />

      {!value.passed && value.findingsCount > 0 && (
      <TooltipOnlyOnOverflow tooltipText={text}>
        <JitText
          color={colors.lightGrey}
          noWrap
          overflowHiddenEllipsis
          text={text}
        />
      </TooltipOnlyOnOverflow>
      )}
    </div>
  );
};
