import { Dispatch, FC, SetStateAction, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CustomDialog } from '../CustomDialog';

import styles from './ShareDialog.module.scss';

import { SlackIcon } from 'assets';
import { JitAutoCompleteForm } from 'components/JitAutoCompleteForm/components/JitAutocompleteForm';
import { JitLink } from 'components/JitLink/JitLink';
import { JitText } from 'components/JitText/JitText';
import { useAuthContext } from 'context/AuthContext/AuthContext';
import { useGetSlackUserVendor } from 'context/TenantContext/tenantHooks/useGetSlackUserVendor';
import { useToastsContext } from 'context/ToastsContext/ToastsContext';
import { constants } from 'globalConstants';
import { app } from 'locale/en';
import { sendShareAction } from 'services/NotificationService';
import colors from 'themes/colors.module.scss';
import { ToastType } from 'types/enums';
import { IAction, IShareAction } from 'types/interfaces';
import { ActionButton, ActionButtonVariant } from 'types/interfaces/ActionButton/ActionButton';
import { IUserVendorServerRequest } from 'types/interfaces/IUserVendor';
import { stopPropagation } from 'utils';

const texts = app.dialogs.shareDialog;

interface Props {
  action: IAction
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  occurrences: number
}

const sendShare = async (action: IAction, name: string, occurrences: number, ids: string[]) => {
  const shareActionData: IShareAction = {
    action,
    sender: name,
    occurrences,
    targets: [],
  };
  ids.forEach((id) => {
    shareActionData.targets.push({ slack: { channel_id: id } });
  });
  await sendShareAction(shareActionData);
};

export const ShareDialog: FC<Props> = ({ isOpen, setIsOpen, action, occurrences }) => {
  const userName = useAuthContext().frontEggUser.name;
  const navigate = useNavigate();
  const { showToast } = useToastsContext();
  const { slackUsersVendors, isLoading } = useGetSlackUserVendor();
  const [selected, setSelected] = useState<IUserVendorServerRequest[]>([]);
  const getDisplayName = (user: IUserVendorServerRequest) => user.username || user.user_vendor_id;
  const names = useMemo(() => (slackUsersVendors || []).sort(
    (a, b) => (getDisplayName(a) < getDisplayName(b) ? 0 : 1),
  ), [slackUsersVendors]);

  const acceptShareClick = () => {
    const users = selected.map((user) => getDisplayName(user));
    sendShare(action, userName, occurrences, selected.map((user) => user.user_vendor_id));
    setIsOpen(false);
    showToast({
      type: ToastType.Success,
      overrideProps: {
        title: `Action shared with ${users.join(', ')}.`,
      },
    });
  };
  const navigateToActionPage = () => navigate(`/${constants.routes.INTEGRATIONS}`);
  const buttons: ActionButton[] = [
    {
      label: texts.actions.cancel,
      handleClick: () => { setIsOpen(false); },
      variant: ActionButtonVariant.SECONDARY,
    },
    {
      label: texts.actions.send,
      handleClick: () => {
        acceptShareClick();
        setIsOpen(false);
      },
      disabled: !selected.length,
      variant: ActionButtonVariant.PRIMARY,
    },
  ];
  const content = (
    <div className={styles.wrapper} data-testid='shareDialog'>
      <SlackIcon className={styles.shareIcon} />

      <JitText className={styles.subTitle} text={texts.subtTitle} />

      <JitText color={colors.gray} text={texts.subtext} />

      <div className={styles.form}>
        <JitAutoCompleteForm
          getOptionLabel={getDisplayName}
          isLoading={isLoading}
          options={names}
          placeHolder={texts.placeHolder}
          selectedValue={selected}
          setSelectedValue={setSelected}
        />
      </div>

      <div>
        <JitText className={styles.inlineText} color={colors.gray} text={texts.footer} />

        <JitLink
          onClick={navigateToActionPage}
          sx={{ cursor: 'pointer' }}
        >
          {/* Don't try to add the color the scss, the component overrides the color */}

          <JitText className={styles.inlineText} color={colors.iris} text={texts.integrationPage} />
        </JitLink>
      </div>
    </div>
  );
  return (
    <CustomDialog
      actionButtons={buttons}
      content={content}
      data-testid='customDialog'
      isOpen={isOpen}
      onClick={stopPropagation}
      onClose={() => setIsOpen(false)}
      title={texts.title}
      width='m'
      withDivider
    />
  );
};

