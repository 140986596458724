import { FC, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { GUIDE_ROUTE, LEADERBOARD_ROUTE, TEAM_ROUTE } from './constants';

import { JitText } from 'components/JitText/JitText';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext/hooks/useSendAnalyticsEvent';
import { ITeam } from 'types/interfaces/Teams/ITeam';
import styles from 'wrappers/TeamsPortalLayout/components/PageHeader/PageHeader.module.scss';

export enum AnalyticsAction {
  QUICK_GUIDE_CLICK = 'quick-guide-click',
  LEADERBOARD_CLICK = 'leaderboard-click',
  TEAM_PAGE_CLICK = 'team-page-click',
}

interface Props {
  currentTeam?: ITeam;
}

export const NavigationButtons: FC<Props> = ({ currentTeam }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isDefaultSelected = useMemo(() => pathname !== LEADERBOARD_ROUTE && !pathname.includes(TEAM_ROUTE), [pathname]);
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const handleClick = useCallback((route: string, action: AnalyticsAction) => {
    navigate(route);
    if (currentTeam) {
      sendAnalyticsEvent({
        action,
        params: {
          team_id: currentTeam.id,
          team_name: currentTeam.name,
        },
      });
    }
  }, [currentTeam, navigate, sendAnalyticsEvent]);
  const guideButton = useMemo(() => ({
    key: 'guide-header-navigation',
    text: 'pages.teamsPortal.header.guide',
    onClick: () => {
      handleClick(GUIDE_ROUTE, AnalyticsAction.QUICK_GUIDE_CLICK);
    },
    isSelected: pathname === GUIDE_ROUTE || isDefaultSelected,
  }), [isDefaultSelected, navigate, pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  const leaderboardButton = useMemo(() => ({
    key: 'leaderboard-header-navigation',
    text: 'pages.teamsPortal.header.leaderboard',
    onClick: () => {
      handleClick(LEADERBOARD_ROUTE, AnalyticsAction.LEADERBOARD_CLICK);
    },
    isSelected: pathname === LEADERBOARD_ROUTE,
  }), [handleClick, pathname]);

  const teamPageButton = useMemo(() => ({
    key: 'team-header-navigation',
    text: 'pages.teamsPortal.header.teamPage',
    onClick: () => {
      handleClick(`${TEAM_ROUTE}/${currentTeam?.id}`, AnalyticsAction.TEAM_PAGE_CLICK);
    },
    isSelected: pathname.includes(TEAM_ROUTE),
  }), [currentTeam?.id, handleClick, pathname]);

  const navigationButtons = useMemo(
    () => {
      if (!currentTeam) return [];

      return (currentTeam?.isActivated ? [
        teamPageButton,
        guideButton,
        leaderboardButton,
      ] : [
        guideButton,
      ]);
    },
    [currentTeam, guideButton, leaderboardButton, teamPageButton],
  );

  return (
    <div className={styles.navigateButtons} data-testid='header-navigate-buttons'>
      {navigationButtons.map((button) => (
        <JitText key={button.key} bold={button.isSelected} data-testid={button.key} onClick={() => button.onClick()} text={button.text} />
      ))}
    </div>
  );
};
