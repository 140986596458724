import { isEqual, pick } from 'lodash';

import { authModeToExtraFieldsInitialState } from '../Zap/constants';
import { validateZapLogin } from '../Zap/ZapLoginValidator';

import { logInfo } from 'services/logger/logger';
import { validateUrlExistence } from 'services/ValidationService/ValidationService';
import { AssetType } from 'types/enums';
import { IConfigurations, ZapApplication, ZapApplicationFormBasedAuth, ZapAuthMode, ZapAuthenticationConfigType } from 'types/interfaces';

export const zapValidator = async (zapType: ZapAuthenticationConfigType, selectedApplication: ZapApplication, configurations: IConfigurations) => {
  const isFormBasedAuth = (tested?: ZapApplication): tested is ZapApplicationFormBasedAuth => tested?.authentication_mode === ZapAuthMode.FormBased;

  const isFormBasedAuthExtraFieldChanged = (tested?: ZapApplication): tested is ZapApplicationFormBasedAuth => {
    const AuthExtraFields = Object.keys(authModeToExtraFieldsInitialState[ZapAuthMode.FormBased]);
    const fetchedApplication = configurations?.applications?.find((app) => app.application_name === selectedApplication.application_name) as ZapApplication | undefined;

    const applicationAuthExtraFields = pick(tested, AuthExtraFields);
    const fetchedApplicationAuthExtraFields = pick(fetchedApplication, AuthExtraFields);
    return !isEqual(applicationAuthExtraFields, fetchedApplicationAuthExtraFields);
  };

  const isWebsiteValidated = selectedApplication.target_url && zapType === AssetType.WEB
    ? await validateUrlExistence(selectedApplication.target_url)
    : false;

  if (!isFormBasedAuth(selectedApplication) || !isFormBasedAuthExtraFieldChanged(selectedApplication)) {
    if (isWebsiteValidated || zapType === AssetType.API) {
      return {
        isValid: true,
        reason: 'configurations.zap.validation.noValidationRequired',
      };
    }
    return {
      isValid: false,
      reason: 'configurations.zap.validation.websiteDoesNotExist',
    };
  }

  const { success, message } = await validateZapLogin(selectedApplication);
  logInfo('Zap configurations validated', { selectedApplication,
    success,
    message });
  return {
    isValid: success,
    reason: message,
  };
};
