/* eslint-disable react/jsx-max-depth */

import { useAuthUser } from '@frontegg/react';
import TextField from '@mui/material/TextField';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import styles from './NewOrganizationDialog.module.scss';

import { JitCelebrationIllustration } from 'assets';
import { CustomDialog } from 'components/JitDialogs/CustomDialog';
import { JitGradientButton } from 'components/JitGradientButton/JitGradientButton';
import { JitText } from 'components/JitText/JitText';
import { useTenantContext } from 'context/TenantContext';
import { useSwitchTenant } from 'context/TenantContext/tenantHooks/useSwitchTenant';
import { createVendorTenant } from 'services/TenantService';
import colors from 'themes/colors.module.scss';

interface NewOrganizationDialogProps {}

export const NewOrganizationDialog: React.FC<NewOrganizationDialogProps> = () => {
  const { isAddingNewOrganization, setIsAddingNewOrganization } = useTenantContext();
  const frontEggUser = useAuthUser();
  const [isSwitchingTenant, setIsSwitchingTenant] = React.useState(false);
  const { handleSwitchTenant } = useSwitchTenant();
  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleCreateOrganization = () => {
    setIsSwitchingTenant(true);
    const { email, provider } = frontEggUser;
    if (inputRef.current?.value) {
      createVendorTenant({
        email,
        name: inputRef.current?.value,
        tenant_id: uuidv4(),
        provider,
      }).then((res) => {
        const newTenantId = res?.tenant_id;
        if (newTenantId) handleSwitchTenant(newTenantId);
      }).finally(() => {
        setIsSwitchingTenant(false);
      });
    }
  };

  const handleClose = () => {
    setIsAddingNewOrganization(false);
  };

  const rightComponent = (
    <JitCelebrationIllustration
      className={styles.jitCelebrationIllustration}
      data-testid='jit-celebration-illustration'
    />
  );

  const leftComponent = (
    <div className={styles.left}>
      <div>
        <JitText bold gutterBottom size='l' text='navbar.menu.createOrganization.title' />

        <JitText color={colors.lightGray} gutterBottom letterSpacing={0.2} size='m' text='navbar.menu.createOrganization.description' />
      </div>

      <JitText muted text='Organization Name' />

      <TextField
        className={styles.orgNameInput}
        inputProps={{
          sx: {
            padding: '14px',
          },
        }}
        inputRef={inputRef}
        placeholder='Enter Organization Name'
      />

      <JitGradientButton
        data-testid='create-organization-modal-button'
        isLoading={isSwitchingTenant}
        onClick={handleCreateOrganization}
      >
        <div className={styles.button}>
          <JitText text='navbar.menu.createOrganization.createBtn' />
        </div>
      </JitGradientButton>
    </div>
  );

  const content = (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        {leftComponent}
      </div>

      <div className={styles.right}>
        {rightComponent}
      </div>
    </div>
  );

  return (
    <CustomDialog
      backgroundColor={colors.navbarBackground}
      borderColor={colors.darkGray03}
      content={content}
      contentPadding={7}
      isOpen={isAddingNewOrganization}
      onClose={handleClose}
      shouldShowEdgesBackground={false}
      width='xl'
    />
  );
};
