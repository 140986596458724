import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Popper, { PopperPlacementType } from '@mui/material/Popper';
import { FC, ReactElement, useEffect, useMemo, useRef } from 'react';

import jitPopperStyles from './JitPopper.module.scss';
import brightStyles from './JitPopperBrightVariant.module.scss';
import darkStyles from './JitPopperDarkVariant.module.scss';

import colors from 'themes/colors.module.scss';

const FADE_DURATION = 350;

export type JitPopperVariants = 'dark' | 'bright';

type PossibleArrowPlacements =
  'leftStart'
  | 'left'
  | 'leftEnd'
  | 'rightStart'
  | 'right'
  | 'rightEnd'
  | 'topStart'
  | 'top'
  | 'topEnd'
  | 'bottomStart'
  | 'bottom'
  | 'bottomEnd';

interface Props {
  content: ReactElement;
  className?: string;
  children: ReactElement;
  open: boolean;
  onClose?: () => void;
  isWithArrow?: boolean;
  placement?: PopperPlacementType;
  arrowPlacement?: PossibleArrowPlacements;
  transition?: boolean;
  shadowVariant?: 'light' | 'dark' | 'none';
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  variant?: JitPopperVariants;
  disablePortal?: boolean;
  keepMounted?: boolean;
  withBackdrop?: boolean;
}

export const JitPopper: FC<Props> = ({
  withBackdrop = false, content, children, open, onClose = () => {}, className = '', isWithArrow = true, placement = 'right', arrowPlacement = 'top',
  transition = true, shadowVariant = 'light', onMouseEnter, onMouseLeave, variant = 'dark', disablePortal = true, keepMounted,
}) => {
  const anchorElementRef = useRef<HTMLDivElement>(null);
  const popperRef = useRef<HTMLDivElement>(null);

  const styles = useMemo(() => {
    if (variant === 'bright') {
      return brightStyles;
    }

    return darkStyles;
  }, [variant]);

  const defaultVirtualElement = {
    getBoundingClientRect: () => ({
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: 0,
      height: 0,
    }),
  };
  const boxShadowStyles = {
    light: styles.lightShadow,
    dark: styles.darkShadow,
    none: '',
  };

  const paperStyle = {
    borderRadius: 8,
    ...(variant === 'bright' ? { backgroundColor: colors.white } : {}),
  };

  useEffect(() => {
    const isFixedPositionElementRecursive = (element: HTMLElement): boolean => {
      // We want to avoid closing the popper when clicking on fixed position elements
      if (!element) return false;

      if (window.getComputedStyle(element).position === 'fixed') return true;

      return isFixedPositionElementRecursive(element.parentElement as HTMLElement);
    };
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popperRef.current
        && !popperRef.current.contains(event.target as Node)
        && anchorElementRef.current
        && !anchorElementRef.current.contains(event.target as Node)
        && !isFixedPositionElementRecursive(event.target as HTMLElement)
      ) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <div data-testid='jit-popper'>
      <div ref={anchorElementRef}>
        {children}
      </div>

      {withBackdrop && <Backdrop className={jitPopperStyles.backdrop} open={open} />}

      <Popper
        ref={popperRef}
        anchorEl={anchorElementRef.current || defaultVirtualElement as HTMLDivElement}
        className={className}
        disablePortal={disablePortal}
        keepMounted={keepMounted}
        modifiers={[{
          name: 'preventOverflow',
          enabled: true,
          options: {
            boundariesElement: 'window',
            padding: 40,
          },
        }]}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        open={open}
        placement={placement}
        style={{
          zIndex: 1000,
          padding: 12,
        }}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={transition ? FADE_DURATION : 0}>
            <Paper sx={paperStyle}>
              <Paper className={styles.popoverRoot} sx={paperStyle}>
                {isWithArrow && (
                  <>
                    <span className={`${styles.pic} ${styles[arrowPlacement]}`} />

                    <span className={`${styles.pic} ${styles[`${arrowPlacement}Border`]}`} />
                  </>
                )}

                <Box className={`${styles.popoverPadding} ${boxShadowStyles[shadowVariant]}`}>{content}</Box>
              </Paper>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
};
