import { FC } from 'react';

import styles from './JitTag.module.scss';

import { Cross } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText, TextSize } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import colors from 'themes/colors.module.scss';

interface Props {
  text: string;
  color: string;
  borderRadius?: number;
  padding?: string;
  textSize?: TextSize;
  bold?: boolean;
  onDelete?: () => void;
  deleteIconTooltipText?: string;
  withBorder?: boolean;
}

export const JitTag: FC<Props> = ({
  onDelete, deleteIconTooltipText, text, color, withBorder = true, borderRadius = 8, padding, textSize = 's', bold = true,
}) => (
  <div
    className={styles.tagContainer}
    style={{
      border: withBorder ? `1px solid ${color}` : 'none',
      borderRadius,
      padding,
    }}
  >
    <JitText
      bold={bold}
      color={color}
      data-testid='card-tag'
      noWrap
      overflowHiddenEllipsis
      size={textSize}
      text={text}
    />

    {onDelete && (
      <JitTooltip followCursor={false} placement='top' title={deleteIconTooltipText || ''}>
        <JitIcon
          color={colors.cards}
          icon={Cross}
          onClick={onDelete}
          size={12}
          withBackSquare={{
            backgroundColor: colors.iris,
            borderRadius: 10,
            height: 16,
            width: 16,
            borderColor: 'transparent',
          }}
        />
      </JitTooltip>
    )}
  </div>
);
