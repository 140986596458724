import { FC, useCallback } from 'react';

import styles from './ToggleIgnoreFinding.module.scss';

import { Eye, EyeCrossed } from 'assets';
import { IconButton } from 'components/IconButton/IconButton';
import { JitButton } from 'components/JitButton/JitButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { useAuthContext } from 'context/AuthContext/AuthContext';
import colors from 'themes/colors.module.scss';
import { PERMISSIONS } from 'wrappers/RBAC';

interface Props {
  isIgnored: boolean;
  onSetIsIgnored: (newValue: boolean) => void;
  fullSize?: boolean;
}

export const ToggleIgnoreFinding: FC<Props> = ({ isIgnored, onSetIsIgnored, fullSize = true }) => {
  const { hasPermission } = useAuthContext();
  const isIgnoreRulesReadOnly = !hasPermission(PERMISSIONS.WRITE_IGNORE_RULES);

  const text = isIgnored ? 'pages.findings.toggleIgnoreFindings.undoIgnore' : 'pages.findings.toggleIgnoreFindings.ignore';
  const icon = isIgnored ? Eye : EyeCrossed;

  const handleIgnored = useCallback(() => {
    onSetIsIgnored(!isIgnored);
  }, [isIgnored, onSetIsIgnored]);

  if (fullSize) {
    return (
      <div className={styles.wrapper}>
        <JitButton
          data-testid='toggle-ignore-finding-button'
          disabled={isIgnoreRulesReadOnly}
          onClick={handleIgnored}
          sx={{
            ':hover': {
              borderColor: colors.iris,
            },
          }}
          variant='outlined'
        >
          <div className={styles.button}>
            <JitIcon
              color='transparent'
              icon={icon}
              size='14px'
            />

            <JitText
              color={colors.iris}
              text={text}
            />
          </div>

        </JitButton>
      </div>
    );
  }

  return (
    <IconButton color='none' icon={icon} isDisabled={isIgnoreRulesReadOnly} onClick={handleIgnored} testId='toggle-ignore-finding-button' tooltipText={text} />
  );
};
