import { FC, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { SeeDetailsButton } from '../SeeDetailsButton/SeeDetailsButton';

import styles from './ResourceBreakdownCard.module.scss';

import { JitText } from 'components/JitText/JitText';
import { PriorityFactorsBox } from 'components/PriorityFactorsBox/PriorityFactorsBox';
import { constants } from 'globalConstants';
import { PriorityBox } from 'pages/FindingsPage/components/PriorityBox/PriorityBox';
import { ResourcesStatistics } from 'pages/Resources/ResourcesDashboardPage/components/ResourcesStatistics/ResourcesStatistics';
import colors from 'themes/colors.module.scss';
import { IAggregatedAssetStatistics } from 'types/interfaces';
import { capitalizeFirstLetter } from 'utils';

export const ResourceBreakdownCard: FC<IAggregatedAssetStatistics> = (resourceStatistic) => {
  const {
    aggregationKey,
    description,
    count,
    averagePriorityScore,
    buckets,
  } = resourceStatistic;

  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const tag = (
    <PriorityFactorsBox factors={[{
      key: aggregationKey,
      displayName: capitalizeFirstLetter(aggregationKey),
    }]}
    />
  );
  const { BASE_ROUTE, RESOURCES, RESOURCES_LIST } = constants.routes.risks;
  const navigate = useNavigate();
  const onClick = useCallback(() => {
    const params = new URLSearchParams();
    params.set('priority_factors', aggregationKey);
    const baseUrl = `/${BASE_ROUTE}/${RESOURCES}/${RESOURCES_LIST}`;
    navigate(`${baseUrl}?${params.toString()}`);
  }, [BASE_ROUTE, RESOURCES, RESOURCES_LIST, aggregationKey, navigate]);

  const isDisabled = count === 0;

  return (
    <div
      className={styles.card}
      data-testid='resource-breakdown-card'
      onClick={isDisabled ? undefined : onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      role='button'
      style={{ cursor: isDisabled ? 'default' : 'pointer' }}
      tabIndex={0}
    >
      <div className={styles.cardMainContent}>
        <PriorityBox bold={false} iconSize='m' priorityScore={averagePriorityScore} size='xxxxl' withBorder={false} />

        <JitText bold color={colors.lightGray} size='xs' style={{ marginTop: -14 }} text='pages.risks.resources.resourcesDashboard.riskScore' />

        <div className={styles.cardMainContentDescription}>

          {tag}

          {description && <JitText align='center' display='flex' muted size='m' text={description} />}
        </div>
      </div>

      <div className={styles.cardLowerContainer}>
        {isHovered
          ? <SeeDetailsButton disabled={isDisabled} onClick={onClick} />
          : <ResourcesStatistics priorityBreakdown={buckets} resourcesCount={count} />}
      </div>

    </div>
  );
};
