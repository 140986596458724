import { FC, useEffect, useMemo } from 'react';

import { Actions, Coverage, Insights, SecurityPosture } from '..';
import { useFetchMetrics } from '../../hooks/useFetchMetrics';
import { CollapseSection } from '../CollapseSection/CollapseSection';
import { SecurityPlans } from '../SecurityPlans/SecurityPlans';
import { TeamsLeaderboard } from '../TeamsLeaderboard/TeamsLeaderboard';

import styles from './OverviewBody.module.scss';

import { usePlansContext } from 'context/PlansContext/PlansContext';
import { usePullRequestsContext } from 'context/PullRequestsContext/PullRequestsContext';
import { useFetchActions, useFetchActionsCount } from 'services/ActionService/useFetchActions';
import { OverviewMetricNames } from 'types/enums/MetricNames';

export const OverviewBody: FC = () => {
  const { prAnalysis, isLoading: prLoading, getPullRequestsAnalysis } = usePullRequestsContext();
  const { actions, isLoading: isLoadingActionsData, getActions } = useFetchActions();
  const { isLoading: isLoadingActionsCount, actionsCount = 0, getActionsCount } = useFetchActionsCount();
  const { metrics, fetchMetrics, silentFetchDevProductivity } = useFetchMetrics();
  const { fetchPlans, hasFetchedPlans, plans } = usePlansContext();

  useEffect(() => {
    if (!hasFetchedPlans) fetchPlans();
  }, [fetchPlans, hasFetchedPlans]);

  useEffect(() => {
    fetchMetrics();
  }, [fetchMetrics]);

  useEffect(() => {
    if (!prLoading && !prAnalysis) {
      getPullRequestsAnalysis();
    }
  }, [getPullRequestsAnalysis, prAnalysis, prLoading]);

  useEffect(() => {
    getActions();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we only want it to run once
  }, []);

  useEffect(() => {
    getActionsCount();
  }, [getActionsCount]);

  const goals = useMemo(() => {
    if (!plans) return [];

    return Object.values(plans).filter((plan) => plan.is_goal);
  }, [plans]);

  const {
    [OverviewMetricNames.TrendingDevProductivity]: devProductivityMetric,
    [OverviewMetricNames.MTTR]: mttrMetric,
    [OverviewMetricNames.ResourcesCoverage]: resourceCoverageMetric,
    [OverviewMetricNames.LayersCoverage]: planStatusMetric,
    [OverviewMetricNames.PlanItemFindings]: planItemFindingsMetric,
  } = metrics;

  const isLoadingInsights = useMemo(() => devProductivityMetric.isLoading || mttrMetric.isLoading || prLoading, [devProductivityMetric.isLoading, mttrMetric.isLoading, prLoading]);
  const isLoadingActions = useMemo(() => isLoadingActionsData || isLoadingActionsCount, [isLoadingActionsData, isLoadingActionsCount]);

  return (
    <div className={styles.bodyContent}>
      <div className={styles.insightsAndLeaderboardContainer}>
        <div className={styles.insights}>
          <Insights
            devProductivityMetric={devProductivityMetric.metric}
            isLoading={isLoadingInsights}
            mttrMetric={mttrMetric.metric}
            prAnalysis={prAnalysis}
            silentFetchDevProductivity={silentFetchDevProductivity}
          />
        </div>

        <div className={styles.leaderboard}>
          <TeamsLeaderboard />
        </div>
      </div>

      <CollapseSection title='pages.overview.securityPlans.title'>
        <SecurityPlans goals={goals} />
      </CollapseSection>

      <CollapseSection testId='Actions-wrapper' title='actions.title'>
        <Actions actions={actions.data} count={actionsCount} isLoading={isLoadingActions} />
      </CollapseSection>

      <div className={styles.coverageSecurityPosture}>
        <Coverage planStatusMetric={planStatusMetric} resourcesCoverageMetric={resourceCoverageMetric} />

        <SecurityPosture planItemFindingsMetric={planItemFindingsMetric} />
      </div>
    </div>
  );
};
