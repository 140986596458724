import { FC } from 'react';

import styles from './ResourceBreakdownGrid.module.scss';

import {
  ResourceBreakdownCard,
} from 'pages/Resources/ResourcesDashboardPage/components/ResourceBreakdownCard/ResourceBreakdownCard';
import { IAggregatedAssetStatistics } from 'types/interfaces';

interface Props {
  resourcesStatistic: IAggregatedAssetStatistics[];
  keysToDisplay?: {
    key: string;
    description: string;
  }[];
}

export const ResourceBreakdownGrid: FC<Props> = ({ resourcesStatistic, keysToDisplay }) => {
  const filteredResourcesStatistic = keysToDisplay ? resourcesStatistic.filter(
    (resourceStatistic) => keysToDisplay.map(({ key }) => key).includes(resourceStatistic.aggregationKey),
  ) : resourcesStatistic;
  const missingKeys = keysToDisplay?.filter(({ key }) => !resourcesStatistic.find((resource) => resource.aggregationKey === key));
  const missingResources = missingKeys?.map(({ key, description }) => ({
    aggregationKey: key,
    description,
    count: 0,
    averagePriorityScore: 0,
    buckets: {
      critical: 0,
      high: 0,
      medium: 0,
      low: 0,
    },
  }));
  const allResourcesStatistic = [...filteredResourcesStatistic, ...(missingResources || [])];
  return (
    <div className={styles.grid}>
      {allResourcesStatistic.sort(
        (a, b) => b.averagePriorityScore - a.averagePriorityScore,
      ).map((resourceStatistic) => (
        <ResourceBreakdownCard key={resourceStatistic.aggregationKey} {...resourceStatistic} />
      ))}
    </div>
  );
};
