import { FC, useEffect, useMemo, useState } from 'react';

import { IntegrateSlackContent } from '../IntegrateSlackContent/IntegrateSlackContent';
import { InviteSlackUsersContent } from '../InviteSlackUsers/InviteSlackUsersContent';

import { HyperLink } from 'assets';
import { CustomDialog } from 'components/JitDialogs/CustomDialog';
import { useGetInvitationLink } from 'context/TenantContext/tenantHooks/useGetInvitationLink';
import { useSlackIntegrationStatus } from 'pages/TeamsPage/SubPages/TeamsPage/hooks/useSlackIntegrationStatus';
import { ActionButton, ActionButtonVariant } from 'types/interfaces';
import { ROLES } from 'wrappers/RBAC';

interface Props {
  onClose: () => void;
  isOpen: boolean;
}

export const InviteMembersDialog: FC<Props> = ({ onClose, isOpen }) => {
  const { getInvitationLink } = useGetInvitationLink();
  const { isConnected, isPostInstallation } = useSlackIntegrationStatus();
  const [isCopied, setIsCopied] = useState(false);
  const [invitationLink, setInvitationLink] = useState<string>();
  const [shouldShowIntegrateSlackContent, setShouldShowIntegrateSlackContent] = useState(true);

  useEffect(() => {
    if (!invitationLink && isOpen) {
      getInvitationLink(
        ROLES.ENGINEERING_MANAGER,
      ).then((invitationLinkResult) => {
        if (invitationLinkResult) {
          setInvitationLink(invitationLinkResult);
        }
      });
    }
  }, [getInvitationLink, invitationLink, isOpen]);

  useEffect(() => {
    if (isConnected && !isPostInstallation) {
      setShouldShowIntegrateSlackContent(false);
    }
  }, [isConnected, isPostInstallation]);

  const content = useMemo(() => (shouldShowIntegrateSlackContent ? <IntegrateSlackContent /> : <InviteSlackUsersContent role={ROLES.ENGINEERING_MANAGER} />
  ), [shouldShowIntegrateSlackContent]);

  const actionButtons: ActionButton[] = useMemo(
    () => (shouldShowIntegrateSlackContent ? [
      {
        label: 'pages.teams.invite.dialog.integrateSlack.button',
        handleClick: () => setShouldShowIntegrateSlackContent(false),
        variant: ActionButtonVariant.PRIMARY,
        disabled: !isConnected,
      },
    ] : [
    ]),
    [shouldShowIntegrateSlackContent, isConnected],
  );

  const titleActionButtons: ActionButton[] = useMemo(() => ([{
    label: 'pages.teams.invite.dialog.copyInviteLink',
    handleClick: () => {
      if (invitationLink) {
        navigator.clipboard.writeText(invitationLink).then(() => {
          setIsCopied(true);
        });
      }
    },
    handleMouseEnter: () => {
      setIsCopied(false);
    },
    tooltip: isCopied ? 'pages.teams.invite.dialog.copiedToClipboardTooltip' : 'pages.teams.invite.dialog.copyToClipboardTooltip',
    isLoading: !invitationLink,
    disabled: !invitationLink,
    variant: ActionButtonVariant.SECONDARY,
    icon: HyperLink,
  }]), [invitationLink, isCopied]);

  return (
    <CustomDialog
      actionButtons={actionButtons}
      actionButtonsPadding='0 30px 30px 0'
      content={content}
      dataTestId='invite-members-dialog'
      isOpen={isOpen}
      onClose={onClose}
      title='pages.teams.invite.dialog.title'
      titleActionButtons={titleActionButtons}
      width='l'
      withDivider
    />
  );
};
