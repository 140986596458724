import Divider from '@mui/material/Divider';
import { FC } from 'react';

import colors from 'themes/colors.module.scss';

export const JitDivider: FC = () => {
  const borderColor = colors.cardsDivider;

  return <Divider style={{ borderColor }} />;
};
