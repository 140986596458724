import Collapse from '@mui/material/Collapse';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC, useState } from 'react';

import { AssetManagementTable } from '../AssetManagementTable/AssetManagementTable';

import styles from './OwnerAccordion.module.scss';

import { ChevronClosed, ChevronOpened } from 'assets';
import { JitCheckbox } from 'components/JitCheckbox/JitCheckbox';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { i18n } from 'locale/i18n';
import colors from 'themes/colors.module.scss';
import { IAsset, IChangeEvent } from 'types/interfaces';
import { stopPropagation } from 'utils';

interface Props {
  ownerName: string;
  assets: IAsset[];
  checkAsset: (assetId: string, checked: boolean) => void;
  checkMultipleAssetsByOwner: (assetId: string, checked: boolean) => void;
  accordionOwnerTypeTitle: string;
  accordionTableAssetColumnHeader: string;
}

export const OwnerAccordion: FC<Props> = ({
  ownerName,
  assets,
  checkAsset,
  checkMultipleAssetsByOwner,
  accordionOwnerTypeTitle,
  accordionTableAssetColumnHeader,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const { isControlledRepoActivation } = useFlags();

  const areAllAssetsOfOwnerSelected = assets.every((asset) => asset.is_covered);

  const toggleExpanded = () => setIsExpanded((currentExpanded) => !currentExpanded);

  const handleSelectAllAssetsOfOwner = (e: IChangeEvent, checked: boolean) => {
    checkMultipleAssetsByOwner(ownerName, checked);
  };

  const headersList = [{
    label: accordionTableAssetColumnHeader,
    key: 'asset_name',
  }];

  return (
    <div data-testid='owner'>
      <div
        className={styles.orgContainer}
        data-testid='owner-heading'
        onClick={toggleExpanded}
        role='button'
        tabIndex={0}
      >

        <JitCheckbox
          checked={areAllAssetsOfOwnerSelected}
          className={styles.ownerCheckBox}
          data-testid='owner-checkbox'
          disabled={isControlledRepoActivation}
          id={ownerName}
          onChange={handleSelectAllAssetsOfOwner}
          onClick={stopPropagation}
        />

        <JitText
          className={styles.ownerText}
          text={`${i18n.t(accordionOwnerTypeTitle)}: ${ownerName}`}
        />

        <div className={styles.iconContainer}>
          <JitIcon
            color={colors.gray}
            icon={isExpanded ? ChevronOpened : ChevronClosed}
            iconClassName={styles.icon}
          />
        </div>
      </div>

      <Collapse
        data-testid='owner-details'
        in={isExpanded}
        style={{
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        <div className={styles.contentContainer}>
          <AssetManagementTable assets={assets} checkAsset={checkAsset} headers={headersList} />
        </div>
      </Collapse>
    </div>
  );
};
