import { isEmpty } from 'lodash';
import { FC, useMemo, useState } from 'react';

import styles from './FilterBox.module.scss';

import { ExpandArrow } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitMenu, MenuItemCategories, MenuItemType } from 'components/JitMenu/JitMenu';
import { JitText } from 'components/JitText/JitText';
import { TooltipOnlyOnOverflow } from 'components/TooltipOnlyOnOverflow/TooltipOnlyOnOverflow';
import colors from 'themes/colors.module.scss';
import { MenuItemKeyType } from 'types/interfaces';
import { IFilter, IFilterOption, IFilterType } from 'types/interfaces/IFilter';

interface Props {
  filter: IFilter
  shouldCloseOnItemClick?: boolean
  updateFilter: (updatedFilter: IFilter) => void
}

export const FilterBox: FC<Props> = ({ filter, updateFilter, shouldCloseOnItemClick }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const valueOptions = useMemo(() => (filter.valueOptions && [...filter.valueOptions]), [filter.valueOptions]);
  const valueOptionsWithCategories = useMemo(() => (filter.valueOptionsWithCategories && [...filter.valueOptionsWithCategories]), [filter.valueOptionsWithCategories]);
  const isMultiSelect = useMemo(() => filter.type === IFilterType.MULTI_SELECT, [filter]);
  const isSingleSelect = useMemo(() => filter.type === IFilterType.SINGLE_SELECT, [filter]);
  const numberOfOptions = useMemo(() => {
    const numberOfOptionsNoParent = filter.valueOptions ? filter.valueOptions.length : 0;
    let numberOfOptionsWithParent = 0;
    if (filter.valueOptionsWithCategories) {
      filter.valueOptionsWithCategories.forEach((value) => {
        numberOfOptionsWithParent += value.valueOptions.length;
      });
    }
    return numberOfOptionsNoParent + numberOfOptionsWithParent;
  }, [filter]);

  const isMultiSelectFilterFullySelected = useMemo(
    () => isMultiSelect
  && (isEmpty(filter.selectedValue) || (filter.selectedValue as IFilterOption[]).length === numberOfOptions),
    [filter.selectedValue, numberOfOptions, isMultiSelect],
  );

  const isSingleSelectFilterFullySelected = useMemo(
    () => isSingleSelect && (isEmpty((filter.selectedValue as IFilterOption)?.value)),
    [filter.selectedValue, isSingleSelect],
  );

  const isAllValuesSelected = (isMultiSelectFilterFullySelected) || (isSingleSelectFilterFullySelected);

  const formattedSelectedValue = useMemo(() => {
    if (isAllValuesSelected) {
      return 'filters.allFilterValuesSelected';
    }

    // We need to check if the selected value is an array to prevent a white screen in case
    // isMultiSelect is true but the selectedValue is not an array.
    if (isMultiSelect && Array.isArray(filter.selectedValue)) {
      return filter.selectedValue.map((value) => value.displayText).join(', ');
    }

    return (filter.selectedValue as IFilterOption)?.displayText;
  }, [filter, isAllValuesSelected, isMultiSelect]);

  const menuItems: MenuItemType[] | undefined = valueOptions?.map((filterValueOption) => {
    const isSelected = isMultiSelect
      ? (filter.selectedValue as IFilterOption[]).some(
        (option) => option.value === filterValueOption.value,
      )
      : ((filter.selectedValue as IFilterOption)?.value === filterValueOption?.value
        || (isEmpty((filter.selectedValue as IFilterOption)?.value) && isEmpty(filterValueOption.value)));
    const itemStyle = isMultiSelect ? { marginTop: 5 } : {};
    return ({
      itemKey: filterValueOption.value,
      itemContent: <TooltipOnlyOnOverflow style={itemStyle} text={filterValueOption.displayText} />,
      searchValue: filterValueOption.displayText,
      isSelected,
    });
  });

  const menuItemsWithCategories: MenuItemCategories[] | undefined = valueOptionsWithCategories?.map((filterValueOptionWithCategories) => {
    const filterValueOptions = filterValueOptionWithCategories.valueOptions;
    const items = filterValueOptions.map((filterValueOption) => {
      const isSelected = isMultiSelect
        ? (filter.selectedValue as IFilterOption[])?.includes(filterValueOption)
        : ((filter.selectedValue as IFilterOption)?.value === filterValueOption?.value
        || (isEmpty((filter.selectedValue as IFilterOption)?.value) && isEmpty(filterValueOption.value)));

      return ({
        itemKey: filterValueOption.value,
        itemContent: <TooltipOnlyOnOverflow text={filterValueOption.displayText} />,
        searchValue: filterValueOption.displayText,
        isSelected,
      });
    });

    return ({
      categoryKey: filterValueOptionWithCategories.category,
      categoryContent: filterValueOptionWithCategories.category,
      items,
    });
  });

  const onMenuItemClick = ({ itemKey: filterValue }: { itemKey: MenuItemKeyType }) => {
    const clickedValue = (valueOptions?.find((value) => value.value === filterValue)
    || valueOptionsWithCategories?.find((category) => category.valueOptions.find((value) => value.value === filterValue))?.valueOptions.find((value) => value.value === filterValue)) as IFilterOption;
    let updatedFilter: IFilter = { ...filter };
    if (isMultiSelect) {
      let filterSelectedValue = filter.selectedValue as IFilterOption[];
      if (Array.isArray(filterSelectedValue) && filterSelectedValue.some((option) => option.value === clickedValue.value)) {
        filterSelectedValue = filterSelectedValue.filter((option) => option.value !== clickedValue.value);
      } else {
        filterSelectedValue = [...filterSelectedValue, clickedValue];
      }
      updatedFilter = {
        ...filter,
        selectedValue: filterSelectedValue,
      };
    } else if (isSingleSelect) {
      updatedFilter = {
        ...filter,
        selectedValue: clickedValue,
      };
    }
    updateFilter(updatedFilter);
  };

  const getBoxContainer = () => (
    <div
      className={`${styles.boxContainer} ${isMenuOpen ? styles.openedBoxContainer : ''}`}
      data-testid='filterBoxContainer'
      style={filter.width ? { width: filter.width } : {}}
    >
      <JitText
        className={styles.filterDisplayText}
        color={colors.lightGray}
        data-testid='filterDisplayText'
        text={`${filter.displayText}:`}
      />

      <TooltipOnlyOnOverflow data-testid='filterSelectedValue' text={formattedSelectedValue} tooltipText={formattedSelectedValue} />

      <JitIcon className={styles.expandIcon} data-testid='filterExpandIcon' icon={ExpandArrow} size={10} />

    </div>
  );
  return (
    <JitMenu
      inputColor='light'
      menuItems={menuItems}
      menuItemStyle={{
        fontSize: 10,
      }}
      menuItemsWithCategories={menuItemsWithCategories}
      menuItemsWrapperClassName={styles.menuItemsWrapper}
      menuItemsWrapperStyle={{ width: filter.width }}
      menuSelectedDesign='fillRow'
      onMenuChangeOpenState={(isOpen: boolean) => { setIsMenuOpen(isOpen); }}
      onMenuItemClick={onMenuItemClick}
      searchBoxPlaceHolder={filter.withSearchBox ? 'Search' : undefined}
      shouldCloseOnItemClick={shouldCloseOnItemClick}
      withCheckbox={isMultiSelect}
      withSearchBox={filter.withSearchBox ? {} : undefined}
    >
      {getBoxContainer()}

    </JitMenu>
  );
};
