import { FC } from 'react';

import styles from './JitChip.module.scss';

import { GreenDotCheck, FailedDot } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

interface Props {
  text: string;
  success: boolean;
  variant?: 'outlined' | 'filled';
  textColor?: string;
  edgesShape?: 'circle' | 'rectangle';
}

export const JitChip: FC<Props> = ({ text, success, variant = 'outlined', textColor, edgesShape = 'circle' }) => {
  const borderClass = variant === 'outlined' && (success ? styles.greenBorder : styles.redBorder);
  const backgroundClass = variant === 'filled' && (success ? styles.filledGreenBackground : styles.filledRedBackground);
  const edgesShapeClass = edgesShape === 'rectangle' ? styles.rectangleEdges : styles.circleEdges;

  const icon = success ? GreenDotCheck : FailedDot;
  const chipTextColor = success ? colors.successGreen02 : colors.failRed;

  return (
    <div className={`${styles.wrapper} ${borderClass} ${backgroundClass} ${edgesShapeClass}`}>
      <JitIcon icon={icon} size={15} />

      <JitText bold color={textColor || chipTextColor} size='s' text={text} />
    </div>
  );
};
