import { ZapApplicationFormBasedAuth } from '../../../types/interfaces';

import { logInfo, logWarning } from 'services/logger/logger';
import { validateLoginParams } from 'services/ValidationService/ValidationService';

export const validateZapLogin = async ({
  login_page_url,
  username,
  authentication_value,
  username_css_selector,
  password_css_selector,
  api_domain,
}: ZapApplicationFormBasedAuth): Promise<{ success: boolean, message: string }> => {
  if (!login_page_url || !username || !authentication_value) {
    return {
      success: true,
      message: 'configurations.zap.validation.missingFields',
    };
  }

  const result = await validateLoginParams(
    login_page_url,
    username,
    authentication_value,
    api_domain,
    username_css_selector,
    password_css_selector,
  );
  if (!result) {
    // The API returns 200 if the validation worked, regardless of the login result.
    // If the result is null, it means the API call failed.
    logWarning('Login validation failed');
    return {
      success: true,
      message: 'configurations.zap.validation.loginValidationFailed',
    };
  }
  logInfo('Login validation succeeded', { result });
  return {
    success: result.hasLoggedIn,
    message: result.message,
  };
};
