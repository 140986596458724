import { AdminPortal } from '@frontegg/react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { getNotHiddenTabs } from './utils';

import { ITabItem, Tab, TabItemTypes } from '.';

import { Settings } from 'assets';
import { useAuthContext } from 'context/AuthContext/AuthContext';
import { useDialogsContext } from 'context/DialogsContext/DialogsContext';
import { useSecretsContext } from 'context/SecretsContext';
import { useStiggExtendedContext } from 'context/StiggContext';
import { constants } from 'globalConstants';
import { Dialogs } from 'types/enums';
import { hideApiTokensManagement } from 'utils/functions/fronteggUtils';
import { useRoutesOfUser } from 'wrappers/RBAC';
import { ROLES } from 'wrappers/RBAC/constants';

const { INTEGRATIONS, POLICIES } = constants.routes;

export const useGetBottomTabItems = (shouldDisablePlatform: boolean) => {
  const { showDialog } = useDialogsContext();
  const navigate = useNavigate();
  const { frontEggUser } = useAuthContext();
  const { setShowSecretsManagement } = useSecretsContext();
  const { setShowStiggPayWall } = useStiggExtendedContext();
  const isAdminOrMember = frontEggUser.roles.some((role) => role.key !== ROLES.DEVELOPER);
  const { uiShowGenerateTokenTab, uiShowPaymentPage, uiShowPoliciesPage } = useFlags();
  const { defaultRoute } = useRoutesOfUser();
  const settingsItemsTabs: ITabItem[] = useMemo(
    () => [
      {
        type: TabItemTypes.item,
        route: INTEGRATIONS,
        name: 'navbar.routes.integrations',
        isDefault: defaultRoute.key === INTEGRATIONS,
        isDisabled: shouldDisablePlatform,
      },
      {
        type: TabItemTypes.item,
        route: POLICIES,
        name: 'navbar.routes.policies',
        shouldHide: !uiShowPoliciesPage,
      },
      {
        type: TabItemTypes.item,
        onClick: () => { showDialog({ type: Dialogs.AssetsManagement }); },
        name: 'navbar.buttons.manageResources',
        isDisabled: shouldDisablePlatform,
      },
      {
        type: TabItemTypes.item,
        onClick: () => {
          AdminPortal.show();
          if (!uiShowGenerateTokenTab) {
            hideApiTokensManagement();
          }
          navigate('#/admin-box/users');
        },
        name: 'navbar.buttons.usersAndPermissions',
      },
      {
        type: TabItemTypes.item,
        onClick: () => { setShowSecretsManagement(true); },
        name: 'navbar.buttons.secrets',
      },
      {
        type: TabItemTypes.item,
        onClick: () => { setShowStiggPayWall(true); },
        name: 'navbar.buttons.subscription',
        shouldHide: !uiShowPaymentPage,
      },
    ],
    [defaultRoute.key, shouldDisablePlatform, uiShowPoliciesPage, uiShowPaymentPage, showDialog, uiShowGenerateTokenTab, navigate, setShowSecretsManagement, setShowStiggPayWall],
  );

  const bottomTabs: Tab[] = useMemo(() => [
    {
      type: TabItemTypes.group,
      name: 'navbar.buttons.settings',
      icon: Settings,
      shouldHide: !isAdminOrMember,
      items: settingsItemsTabs,
      isDisabled: shouldDisablePlatform,
    },
  ], [isAdminOrMember, settingsItemsTabs, shouldDisablePlatform]);

  return {
    bottomTabs: getNotHiddenTabs(bottomTabs),
  };
};
