import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';

import { getNotHiddenTabs } from './utils';

import { Tab, TabItemTypes } from '.';

import { PlanPage, BacklogPage, Insights, OverviewPage, Users, SecurityMappingPage, Dots, Risks, QuickstartPage } from 'assets';
import { constants } from 'globalConstants';
import { PERMISSIONS, useRoutesOfUser } from 'wrappers/RBAC';
import { useHasPermissions } from 'wrappers/RBAC/hooks/useHasPermissions';

const {
  FINDINGS, MATRIX, PLAN, PLANS, PIPELINES, ACTIONS, OVERVIEW, QUICKSTART, TEAMS, SECURITY_MAPPING,
  insights: { BASE_ROUTE: BASE_INSIGHTS_ROUTE, PULL_REQUESTS, DEPLOYMENTS, PERFORMANCE, SECURITY_IMPACT, SBOM },
  risks: { BASE_ROUTE: BASE_RISKS_ROUTE, BACKLOG, RESOURCES },
} = constants.routes;

export const useGetPageTabItems = (shouldDisablePlatform: boolean) => {
  const { uiShowSbomPage, uiShowTheLoopPage, uiShowSecurityChampionQuickGuide, uiShowResourcesPage } = useFlags();
  const { defaultRoute } = useRoutesOfUser();
  const { hasPermission } = useHasPermissions();

  const pagesTabs: Tab[] = useMemo(() => [
    {
      type: TabItemTypes.item,
      route: QUICKSTART,
      name: 'navbar.routes.quickstart',
      icon: QuickstartPage,
      isDefault: defaultRoute.key === QUICKSTART,
      shouldHide: !uiShowSecurityChampionQuickGuide || !hasPermission(PERMISSIONS.QUICKSTART),
    },
    {
      type: TabItemTypes.item,
      route: OVERVIEW,
      name: 'navbar.routes.overview',
      icon: OverviewPage,
      isDefault: defaultRoute.key === OVERVIEW,
      isDisabled: shouldDisablePlatform,
    },
    {
      type: TabItemTypes.item,
      route: SECURITY_MAPPING,
      name: 'navbar.routes.securityMapping',
      icon: SecurityMappingPage,
      shouldHide: !uiShowTheLoopPage,
      isNew: true,
      isDisabled: shouldDisablePlatform,
    },
    {
      type: TabItemTypes.item,
      route: PLANS,
      name: 'navbar.routes.securityPlans',
      icon: PlanPage,
      isDefault: [PLAN, PLANS].includes(defaultRoute.key),
      isDisabled: shouldDisablePlatform,
    },
    {
      type: TabItemTypes.item,
      route: TEAMS,
      name: 'navbar.routes.teams',
      icon: Users,
      isDisabled: shouldDisablePlatform,
    },
    ...(uiShowResourcesPage ? [({
      type: TabItemTypes.group,
      name: 'navbar.routes.risks',
      icon: Risks,
      isDisabled: shouldDisablePlatform,
      items: [
        {
          type: TabItemTypes.item,
          route: `${BASE_RISKS_ROUTE}/${BACKLOG}`,
          name: 'navbar.routes.backlog',
        },
        {
          type: TabItemTypes.item,
          route: `${BASE_RISKS_ROUTE}/${RESOURCES}`,
          name: 'navbar.routes.resources',
          shouldHide: false,
        },
      ],
    }) as Tab] : [{
      type: TabItemTypes.item,
      route: FINDINGS,
      name: 'navbar.routes.findings',
      icon: BacklogPage,
      isDisabled: shouldDisablePlatform,
    } as Tab]),
    {
      type: TabItemTypes.item,
      route: MATRIX,
      name: 'navbar.routes.planMatrix',
      shouldHide: true, // Until further notice, this tab is not available
    },
    {
      type: TabItemTypes.group,
      name: 'navbar.routes.insights',
      icon: Insights,
      isDisabled: shouldDisablePlatform,
      items: [
        {
          type: TabItemTypes.item,
          route: `${BASE_INSIGHTS_ROUTE}/${SECURITY_IMPACT}`,
          name: 'navbar.routes.securityImpact',
        },
        {
          type: TabItemTypes.item,
          route: `${BASE_INSIGHTS_ROUTE}/${PERFORMANCE}`,
          name: 'navbar.routes.performance',
        },
        {
          type: TabItemTypes.item,
          route: `${BASE_INSIGHTS_ROUTE}/${SBOM}`,
          name: 'navbar.routes.sbom',
          shouldHide: !uiShowSbomPage,
        },
        {
          type: TabItemTypes.item,
          route: `${BASE_INSIGHTS_ROUTE}/${PULL_REQUESTS}`,
          name: 'navbar.routes.pullRequests',
        },
        {
          type: TabItemTypes.item,
          route: `${BASE_INSIGHTS_ROUTE}/${DEPLOYMENTS}`,
          name: 'navbar.routes.deployments',
        },
      ],
    },
    {
      type: TabItemTypes.group,
      name: 'navbar.routes.more',
      icon: Dots,
      isDisabled: shouldDisablePlatform,
      items: [
        {
          type: TabItemTypes.item,
          route: PIPELINES,
          name: 'navbar.routes.pipelines',
        },
        {
          type: TabItemTypes.item,
          route: ACTIONS,
          name: 'navbar.routes.actions',
        },
      ],
    },
  ], [defaultRoute.key, hasPermission, shouldDisablePlatform, uiShowResourcesPage, uiShowSbomPage, uiShowSecurityChampionQuickGuide, uiShowTheLoopPage]);

  return {
    pagesTabs: getNotHiddenTabs(pagesTabs),
  };
};
