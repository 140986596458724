import { FC, useRef, useState, useEffect, useMemo } from 'react';

import styles from './PriorityFactorsList.module.scss';

import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { PriorityFactorsBox } from 'components/PriorityFactorsBox/PriorityFactorsBox';
import { getTextWidth } from 'components/PriorityFactorsGraph/utils/visualGraphUtils';

interface Props {
  factors: string[];
}

export const PriorityFactorsList: FC<Props> = ({ factors }) => {
  const cellRef = useRef<HTMLDivElement>(null);
  const [maxFactorsInCell, setMaxFactorsInCell] = useState(3); // Default initial value
  const sortedFactors = useMemo(() => factors.sort((a, b) => a.length - b.length), [factors]);

  useEffect(() => {
    const calculateMaxFactors = () => {
      if (cellRef.current) {
        const cellWidth = cellRef.current.clientWidth;
        const gapWidth = 35;
        const reservedWidthForPlus = 36;

        let totalWidth = 0;
        let calculatedMaxFactors = 0;

        for (let i = 0; i < sortedFactors.length; i += 1) {
          const factorWidth = getTextWidth(sortedFactors[i], 14) + gapWidth;
          if (totalWidth + factorWidth + reservedWidthForPlus > cellWidth) break;

          totalWidth += factorWidth;
          calculatedMaxFactors += 1;
        }

        setMaxFactorsInCell(calculatedMaxFactors > 0 ? calculatedMaxFactors : 1);
      }
    };

    const resizeObserver = new ResizeObserver(calculateMaxFactors);
    if (cellRef.current) {
      resizeObserver.observe(cellRef.current);
    }

    return () => {
      if (cellRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        resizeObserver.unobserve(cellRef.current);
      }
    };
  }, [sortedFactors]);

  const factorsToShow = factors.slice(0, maxFactorsInCell);
  const hiddenFactors = factors.slice(maxFactorsInCell);

  return (
    <div ref={cellRef} className={styles.wrapper}>
      {factorsToShow.map((factor) => (
        <PriorityFactorsBox
          key={factor}
          factors={[{
            key: factor,
            displayName: factor,
          }]}
        />
      ))}

      {hiddenFactors.length > 0 && (
        <JitTooltip followCursor={false} placement='top' title={hiddenFactors.join(', ')}>
          <PriorityFactorsBox
            factors={[{
              key: 'more',
              displayName: `+${hiddenFactors.length}`,
            }]}
          />
        </JitTooltip>
      )}
    </div>
  );
};
