/* eslint-disable react/no-array-index-key */
import { isArray } from 'lodash';
import { FC } from 'react';

import { Header } from '../../AssetManagementTable';

import styles from './AssetsTableRow.module.scss';
import { AssetsTableRowCheckBox } from './AssetsTableRowCheckBox';

import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitTag } from 'components/JitTag/JitTag';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { TooltipOnlyOnOverflow } from 'components/TooltipOnlyOnOverflow/TooltipOnlyOnOverflow';
import colors from 'themes/colors.module.scss';
import { AssetStatus } from 'types/enums';
import { IAsset } from 'types/interfaces';
import { capitalizeFirstLetter, iconsMap } from 'utils';

interface Props {
  asset: IAsset;
  isDisabled: boolean;
  checkAsset: (assetId: string, checked: boolean) => void;
  headers: Header[];
}

export const AssetTableRow: FC<Props> = ({
  asset,
  isDisabled,
  checkAsset,
  headers,
}) => (
  <div className={styles.itemContainer} data-testid={`AssetTableRow - ${asset.asset_name}`}>
    {headers.map((header, index) => {
      const content = asset[header.key as keyof typeof asset] as string | undefined;
      if (index === 0) {
        return (
          <div key={`assetManagement-${asset.asset_id}-${index}`} className={styles.cell}>
            <AssetsTableRowCheckBox
              asset={asset}
              checkAsset={checkAsset}
              isDisabled={isDisabled}
            />

            <TooltipOnlyOnOverflow tooltipText={content}>
              <div className={styles.archiveLabelWrapper}>
                <JitText color={colors.lightGray} horizontalAlign='center' noWrap overflowHiddenEllipsis size='m' text={content} />

                {asset.is_archived ? (<JitTag color={colors.archivedLabel} text='dialogs.assetsManagement.archived' />) : ''}
              </div>
            </TooltipOnlyOnOverflow>
          </div>
        );
      }
      if (isArray(content)) {
        const displayContent = content.join(', ');
        return (
          <JitTooltip
            key={`assetManagement-${asset.asset_id}-${index}`}
            childrenWrapperClassName={styles.cell}
            followCursor
            placement='right'
            title={displayContent}
          >

            <JitText color={colors.lightGray} noWrap overflowHiddenEllipsis size='m' text={displayContent} />
          </JitTooltip>
        );
      }
      if (header.key === 'status') {
        const status = asset.status || AssetStatus.CONNECTED;
        const statusColor = status === AssetStatus.CONNECTED ? colors.statusGreen02 : colors.failRed;
        return (
          <div key={`assetManagement-${asset.asset_id}-${index}`} className={styles.cell}>
            <TooltipOnlyOnOverflow tooltipText={status}>
              <JitText
                color={statusColor}
                noWrap
                overflowHiddenEllipsis
                size='m'
                text={capitalizeFirstLetter(status)}
              />
            </TooltipOnlyOnOverflow>

            {asset.status !== AssetStatus.CONNECTED && asset.status_details && (
              <div key={`assetManagement-${asset.asset_id}-${index}`} className={styles.statusDetailsContainer}>
                <JitTooltip className={styles.statusDetailsTooltip} followCursor={false} placement='top' title={asset.status_details}>
                  <JitIcon size={20} {...iconsMap.configuration} />
                </JitTooltip>
              </div>
            )}
          </div>
        );
      }
      return (
        <div key={`assetManagement-${asset.asset_id}-${index}`} className={styles.cell}>
          <TooltipOnlyOnOverflow tooltipText={content}>
            <JitText color={colors.lightGray} noWrap overflowHiddenEllipsis size='m' text={content} />
          </TooltipOnlyOnOverflow>
        </div>
      );
    })}
  </div>
);
