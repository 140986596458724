import styles from './ValidationBanner.module.scss';

import { Alert, Info } from 'assets';
import { JitInfoBanner } from 'components/JitInfoBanner/JitInfoBanner';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

interface ValidationBannerProps {
  isValidationLoading: boolean;
  validationError: string | null;
}

export const ValidationBanner = ({ isValidationLoading, validationError }: ValidationBannerProps) => {
  let banner;
  if (isValidationLoading) {
    banner = (
      <JitInfoBanner bgColor={colors.cardContent} data-testid='info-banner' icon={Info} iconColor={colors.blue}>
        <JitText data-testid='info-banner-text' size='m' text='pages.plan.configurationsDialog.validating' />
      </JitInfoBanner>
    );
  } else if (validationError) {
    banner = (
      <JitInfoBanner bgColor={colors.findingStatusOpenBG} data-testid='error-banner' icon={Alert} iconColor={colors.error}>
        <div className={styles.errorBanner}>

          <JitText data-testid='error-banner-text' size='m' text={validationError} />

          <JitText size='m' text='pages.plan.configurationsDialog.recheck' />
        </div>

      </JitInfoBanner>
    );
  }
  return banner ? (
    <div className={styles.bannerWrapper}>
      {banner}
    </div>
  ) : null;
};
