import { isEmpty } from 'lodash';

import { BACKLOG_ROUTE } from 'pages/Resources/components/RisksBreadcrumbs/RisksBreadcrumbs';
import { filtersToRequestParams } from 'services/AssetsService/useAssetService';
import { IDictionary, IFilter } from 'types/interfaces';
import { ICount, IGroupByCount } from 'types/interfaces/Counts/ICounts';

export const removeEmptyValues = (obj: IDictionary<boolean | string | undefined | string[]>) => {
  const newObj = { ...obj };
  const isEmptyValue = (value: boolean | string | undefined | string[]) => isEmpty(value) && typeof value !== 'boolean';
  Object.keys(newObj).forEach((key) => isEmptyValue(newObj[key]) && delete newObj[key]);
  return newObj as IDictionary<boolean | string | string[]>;
};

const CRITICAL_PRIORITY_SCORE_START = 80;
const HIGH_PRIORITY_SCORE_START = 60;

export const aggregateFindingsCount = (findingsCount: IGroupByCount[] | ICount | undefined) => {
  const typedFindingsCount = (findingsCount || []) as IGroupByCount[];
  return typedFindingsCount.reduce(
    (acc, { key, count }) => {
      const value = parseInt(key, 10);
      if (value >= CRITICAL_PRIORITY_SCORE_START) {
        acc.critical += count;
      } else if (value >= HIGH_PRIORITY_SCORE_START) {
        acc.high += count;
      } else {
        acc.medium += count;
      }
      return acc;
    },
    {
      critical: 0,
      high: 0,
      medium: 0,
    },
  );
};

export const buildBacklogPageUrl = (filters: IFilter[]) => {
  const queryParams = filtersToRequestParams(filters, true);
  const searchParams = new URLSearchParams({
    resolution: 'OPEN',
    ignored: 'false',
  });
  Object.entries(queryParams).forEach(([key, value]) => {
    if (!value) return;
    searchParams.append(key, value.toString());
  });
  return `${BACKLOG_ROUTE}?${searchParams.toString()}`;
};
