import { FC, useEffect, useRef, useState } from 'react';

import colors from '../../themes/colors.module.scss';
import { JitText } from '../JitText/JitText';

import styles from './JitCollapsibleText.module.scss';

interface Props {
  text: string;
  lineHeight?: number;
  collapseLineNum?: number;
}

export const JitCollapsibleText: FC<Props> = ({ text, lineHeight = 21, collapseLineNum = 4 }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [shouldShowMore, setShouldShowMore] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const handleClick = () => setCollapsed(!collapsed);
  const heightLimit = lineHeight * collapseLineNum;

  useEffect(() => {
    if (containerRef.current) {
      const { scrollHeight } = containerRef.current;
      // scrollHeight == total height of text regardless of collapse
      if (scrollHeight > heightLimit) {
        setShouldShowMore(true);
        setCollapsed(true);
      } else {
        setShouldShowMore(false);
        setCollapsed(false);
      }
    }
  }, [containerRef?.current?.scrollHeight, heightLimit]);

  return (
    <>
      <div
        ref={containerRef}
        className={`${styles.longText} ${collapsed ? styles.clamp : ''}`}
        style={collapsed ? { WebkitLineClamp: collapseLineNum,
          lineClamp: collapseLineNum } : {}}
      >
        <JitText
          text={text}
        />
      </div>

      {shouldShowMore && (
        <JitText
          color={colors.blue02}
          onClick={handleClick}
          text={collapsed ? 'jitCollapsibleText.showMore' : 'jitCollapsibleText.showLess'}
        />
      )}

    </>
  );
};
