import { FC } from 'react';

import 'reactflow/dist/style.css';

import { Graph } from './Graph';
import styles from './MockPriorityFactorsGraph.module.scss';
import { buildNode } from './utils/visualGraphUtils';

import { GradientLock } from 'components/GradientLock/GradientLock';
import { JitExternalLink } from 'components/JitExternalLink/JitExternalLink';
import { config } from 'config';
import { AssetMock } from 'mocks/Assets/assetsMocks';
import { GraphEntityType } from 'types/enums/ContextGraphEntityType';

export const MockPriorityFactorsGraph: FC = () => {
  const nodesData = [
    {
      type: 'GitHub Repository',
      name: 'github-org/github-repo',
      id: 'GitHub Repository#github-org/github-repo',
      factors: [],
    },
    {
      type: 'Team',
      name: 'Dev Team',
      id: 'Team#Dev Team',
      factors: [],
    },
    {
      type: 'AWS Lambda',
      name: 'aws-lambda',
      id: 'AWS Lambda#aws-lambda',
      factors: [],
    },
    {
      type: 'AWS Account',
      name: 'aws-account',
      id: 'AWS Account#aws-account',
      factors: ['Production'],
    },
  ];
  const nodes = nodesData.map((node) => buildNode(node, AssetMock, { id: AssetMock.asset_id,
    name: AssetMock.asset_name,
    priorityFactors: AssetMock.priority_factors,
    priorityScore: AssetMock.priority_score,
    manualFactors: AssetMock.manual_factors,
    type: GraphEntityType.ASSET }));
  const edges = [
    {
      id: '1',
      source: 'Team#Dev Team',
      target: 'GitHub Repository#github-org/github-repo',
    },
    {
      id: '2',
      source: 'GitHub Repository#github-org/github-repo',
      target: 'AWS Lambda#aws-lambda',
    },
    {
      id: '3',
      source: 'AWS Lambda#aws-lambda',
      target: 'AWS Account#aws-account',
    },
  ];
  return (
    <div className={styles.container} data-testid='mock-priority-factors-graph'>
      <div className={styles.graphWrapper}>
        <Graph edges={edges} nodes={nodes} />
      </div>

      <div className={styles.overlay}>
        <div className={styles.overlayText}>
          <GradientLock />

          <JitExternalLink
            href={config.docsContextEngine}
            showIcon
            size='s'
            text='pages.risks.resources.resourcePage.engineDisabled.learnMore'
            withUnderline={false}
          />
        </div>
      </div>

    </div>
  );
};
