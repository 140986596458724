import { difference } from 'lodash';
import { FC, useMemo, useState, useEffect } from 'react';

import 'reactflow/dist/style.css';

import { Graph } from './Graph';
import styles from './PriorityFactorsGraph.module.scss';
import { GraphEntity, CustomNodeType, CustomEdgeType } from './types';
import {
  buildDefaultAssetGraph,
  buildDefaultFindingGraph,
  findMainNode,
  getPriorityFields,
} from './utils/graphDataUtils';
import { usePatchFinalGraph } from './utils/usePatchFinalGraph';
import { buildEdge, buildNode } from './utils/visualGraphUtils';

import { CustomDialog } from 'components/JitDialogs/CustomDialog';
import { useGraphContext } from 'context/GraphContext/GraphContext';
import { useGraphService } from 'services/GraphService/useGraphService';
import { GraphEntityType } from 'types/enums/ContextGraphEntityType';
import { IFinding, IAsset } from 'types/interfaces';
import { IEntityGraph } from 'types/interfaces/Graph/IEntityGraph';

interface Props {
  isFullScreen?: boolean;
  setIsFullScreen?: (isFullScreen: boolean) => void;
  entity?: GraphEntity;
  isLoading?: boolean;

}

export const PriorityFactorsGraph: FC<Props> = ({ entity, isFullScreen, setIsFullScreen, isLoading: isLoadingExternal }) => {
  const { isLoadingPriorityFactors } = useGraphContext();
  const { fetchGraph } = useGraphService();
  const [graphData, setGraphData] = useState<IEntityGraph>();
  const [isLoadingGraph, setIsLoadingGraph] = useState(false);

  const priorityFields = entity && getPriorityFields(entity);
  const { type: entityType, id: entityId, priorityFactors, name } = priorityFields || {};
  useEffect(() => {
    if (graphData || isLoadingGraph || !entity) return;
    fetchGraph(entityId!, entityType!).then((response) => {
      if (response?.status === 200) {
        setGraphData(response.data);
      } else if (response?.status === 404) {
        const defaultGraph = entityType === GraphEntityType.ASSET
          ? buildDefaultAssetGraph(entity as IAsset)
          : buildDefaultFindingGraph(entity as IFinding);
        setGraphData(defaultGraph);
      }
      setIsLoadingGraph(false);
    });
  }, [entity, entityType, entityId, fetchGraph, graphData, isLoadingGraph]);

  const addedToGraph = useMemo(() => {
    if (!entity) {
      return {
        addedEdges: [],
        addedNodes: [],
      };
    }
    const expectedPriorityFactors = priorityFactors || [];
    const factorsInGraph = graphData?.graph?.nodes?.map((node) => node.factors || []).flat() || [];
    const mainNode = entityType && graphData && priorityFields && name && findMainNode(name, graphData);
    if (!mainNode) {
      return {
        addedEdges: [],
        addedNodes: [],
      };
    }

    const missingNodes = difference(expectedPriorityFactors, factorsInGraph);
    const addedNodes = missingNodes.map((factor) => buildNode(
      {
        id: factor,
        type: factor,
        factors: [factor],
      },
      entity,
      priorityFields,
    ));
    const addedEdges = addedNodes.map((node) => buildEdge({
      id: `${mainNode.id}_${node.id}`,
      source: mainNode.id,
      target: node.id,
    }));
    return {
      addedEdges,
      addedNodes,
    };
  }, [entity, graphData, priorityFactors, entityType, name, priorityFields]);

  const nodes: CustomNodeType[] = useMemo(() => {
    if (!graphData || !entity) return [];
    const currentNodes = graphData.graph.nodes.map((node) => buildNode(node, entity, priorityFields!));
    return [...currentNodes, ...addedToGraph.addedNodes];
  }, [graphData, addedToGraph.addedNodes, entity, priorityFields]);

  const edges: CustomEdgeType[] = useMemo(() => {
    if (!graphData) return [];
    const currentEdges = graphData.graph.edges.map((edge) => buildEdge(edge));
    return [...currentEdges, ...addedToGraph.addedEdges];
  }, [addedToGraph.addedEdges, graphData]);

  const { nodesAfterPatch, edgesAfterPatch } = usePatchFinalGraph(nodes, edges);
  const isLoading = isLoadingGraph || isLoadingExternal || isLoadingPriorityFactors || !entity;

  const graphTitle = entityType === GraphEntityType.ASSET
    ? 'pages.risks.resources.resourcePage.graphTitle'
    : 'pages.findings.findingDetails.priority.graphTitle';

  return (
    <div className={styles.wrapper} data-testid='priorityFactorsGraph'>
      <Graph edges={edgesAfterPatch} isLoading={isLoading} nodes={nodesAfterPatch} />

      <CustomDialog
        content={(
          <Graph edges={edgesAfterPatch} isLoading={isLoading} nodes={nodesAfterPatch} />
        )}
        height='fullScreen'
        isOpen={!!isFullScreen}
        onClose={setIsFullScreen ? () => { setIsFullScreen(false); } : undefined}
        title={graphTitle}
        width='fullScreen'
        withDivider
      />
    </div>
  );
};
