import { FC, useState, useEffect, useMemo } from 'react';

import styles from './HandleFixSuggestionButton.module.scss';

import { FixPRIcon, NotificationSuccess, NotificationError } from 'assets';
import { IconButton } from 'components/IconButton/IconButton';
import { JitButton } from 'components/JitButton/JitButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { useAuthContext } from 'context/AuthContext/AuthContext';
import { useToastsContext } from 'context/ToastsContext/ToastsContext';
import {
  ActionSuggestedFixDialog,
} from 'pages/ActionsPage/components/ActionCard/components/ExtendedAction/components/ActionSuggestedFixDialog/ActionSuggestedFixDialog';
import { OpenFixPRToast } from 'pages/FindingsPage/components/HandleFixSuggestionButton/OpenFixPRToast';
import { openFixPR } from 'services/FindingsService';
import colors from 'themes/colors.module.scss';
import { ToastType } from 'types/enums';
import { IFinding } from 'types/interfaces';
import { stopPropagation } from 'utils';
import { PERMISSIONS } from 'wrappers/RBAC';

interface Props {
  finding: IFinding;
  fullSize?: boolean;
}

const OPEN_FIX_PR_WATCHDOG_TIMEOUT_MS = 4 * 60 * 1000; // 4 min

export const HandleFixSuggestionButton: FC<Props> = ({ finding, fullSize = true }) => {
  const { hasPermission } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isFixSuggestionsDialogOpen, setIsFixSuggestionsDialogOpen] = useState(false);
  const { showToast } = useToastsContext();

  const isReadOnly = !hasPermission(PERMISSIONS.WRITE_FINDINGS);

  let buttonText = finding.fixSuggestion?.code_fixes
    ? 'handleFixSuggestion.viewFixSuggestion.buttonText'
    : 'handleFixSuggestion.openFixPR.buttonText';
  if (isLoading) buttonText = 'handleFixSuggestion.openFixPR.loading';

  const openFixSuggestionDialog = () => {
    setIsFixSuggestionsDialogOpen(true);
  };

  const submitOpenFixPR = async () => {
    if (finding.fixPRUrl) {
      window.open(finding.fixPRUrl, '_blank');
    } else {
      setIsLoading(true);
      await openFixPR(finding.id);
      showToast({
        type: ToastType.Success,
        overrideProps: {
          icon: NotificationSuccess,
          title: 'handleFixSuggestion.openFixPR.openingToast.opening.title',
          content: <OpenFixPRToast subtitle='handleFixSuggestion.openFixPR.openingToast.opening.subtitle' />,
          secondsToClose: 20,
        },
      });
      setTimeout(() => {
        if (!finding.fixPRUrl) {
          showToast({
            type: ToastType.Error,
            overrideProps: {
              icon: NotificationError,
              title: 'handleFixSuggestion.openFixPR.openingToast.openingFailure.title',
              content: <OpenFixPRToast subtitle='handleFixSuggestion.openFixPR.openingToast.openingFailure.subtitle' />,
              secondsToClose: 30,
            },
          });
          setIsLoading(false);
        }
      }, OPEN_FIX_PR_WATCHDOG_TIMEOUT_MS);
    }
  };

  useEffect(() => {
    if (isLoading && finding.fixPRUrl) { // We'll reach here due to websocket after the PR has opened
      setIsLoading(false);
    }
  }, [finding.fixPRUrl, isLoading, showToast]);

  const tooltipText = useMemo(() => (finding.fixPRUrl ? 'handleFixSuggestion.openFixPR.viewFixPR' : buttonText), [finding.fixPRUrl, buttonText]);

  const onButtonClick = finding.fixSuggestion?.code_fixes ? openFixSuggestionDialog : submitOpenFixPR;

  if (!finding.fixSuggestion) return null;

  let button;

  if (fullSize) {
    button = (
      <div className={styles.wrapper}>
        <JitTooltip title={finding.fixPRUrl ? 'handleFixSuggestion.openFixPR.viewFixPR' : ''}>
          <JitButton
            data-testid='open-fix-pr-button'
            disabled={isReadOnly || isLoading}
            isLoading={isLoading}
            onClick={onButtonClick}
            sx={{
              ':hover': {
                borderColor: colors.iris,
              },
            }}
            variant='outlined'
          >
            <div className={styles.button}>
              {!isLoading && (
                <JitIcon
                  icon={FixPRIcon}
                  size={14}
                />
              )}

              {!finding.fixPRUrl && (
                <JitText
                  color={colors.iris}
                  text={buttonText}
                />
              )}
            </div>

          </JitButton>
        </JitTooltip>
      </div>

    );
  } else {
    button = (
      <IconButton
        color='none'
        completed={!!finding.fixPRUrl}
        icon={FixPRIcon}
        isDisabled={isReadOnly || isLoading}
        onClick={onButtonClick}
        testId='open-fix-pr-icon-button'
        tooltipText={tooltipText}
      />
    );
  }

  return (
    <div onClick={stopPropagation} role='button' tabIndex={0}>
      {button}

      <ActionSuggestedFixDialog
        fixSuggestion={finding.fixSuggestion}
        isOpen={isFixSuggestionsDialogOpen}
        onClose={() => setIsFixSuggestionsDialogOpen(false)}
      />
    </div>
  );
};
