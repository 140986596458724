import { Collapse } from '@mui/material';
import { FC, ReactElement, useState } from 'react';

import styles from './CollapseSection.module.scss';

import { ExpandArrow } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

interface Props {
  title: string;
  children: ReactElement;
  testId?: string;
}

export const CollapseSection: FC<Props> = ({ title, children, testId }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  const toggleExpand = () => {
    setIsExpanded((prevState) => !prevState);
  };

  return (
    <div data-testid={testId}>
      <div className={styles.header} onClick={toggleExpand} role='button' tabIndex={0}>
        <JitIcon color={colors.iris} icon={ExpandArrow} iconClassName={styles.expandIcon} rotateDegrees={isExpanded ? 0 : -90} size={10} />

        <JitText color={colors.lightGray} text={title} />
      </div>

      <Collapse in={isExpanded}>
        <div className={styles.collapse}>
          {children}
        </div>
      </Collapse>
    </div>
  );
};
