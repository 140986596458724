import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC, useEffect, useMemo } from 'react';
import { useResizeDetector } from 'react-resize-detector';

import { FindingsTable } from '../../components/FindingsTable/FindingsTable';

import { FindingsFilters } from './components';
import { ExportFindingsToCsv } from './components/ExportFindingsToCsv/exportFindingsToCsv';
import { SavedFiltersDropdown } from './components/SavedFiltersDropdown/SavedFiltersDropdown';
import styles from './FindingsPage.module.scss';

import { PageTitles } from 'components/PageTitles/PageTitles';
import { useAuthContext } from 'context/AuthContext/AuthContext';
import { useFindingsContext } from 'context/FindingsContext/FindingsContext';
import { useInitFilters } from 'context/FindingsContext/hooks/useInitFilters';
import { getActiveFiltersKeyValuePair } from 'context/FindingsContext/utils/buildGetFindingsRequestParams';
import { useTenantContext } from 'context/TenantContext';
import { FILTERS_MAX_SIZE_DEFAULT_SIZE, REDUCED_WIDTH_FROM_FILTERS_LINE } from 'pages/FindingsPage/constants';
import { RisksBreadcrumbs } from 'pages/Resources/components/RisksBreadcrumbs/RisksBreadcrumbs';
import { PERMISSIONS } from 'wrappers/RBAC';

export const FindingsPage: FC = () => {
  const { uiShowResourcesPage, uiShowSecurityChampionQuickGuide } = useFlags();
  const { isAnyScmVendorIntegrated } = useTenantContext();
  const { hasPermission } = useAuthContext();
  const { filters, hasFetchedFilters } = useFindingsContext();
  const { initFilters } = useInitFilters();

  useEffect(() => {
    const init = async () => {
      await initFilters();
    };

    init();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const isFindingReadOnly = useMemo(() => !hasPermission(PERMISSIONS.WRITE_FINDINGS), [hasPermission]);

  const { width, ref } = useResizeDetector();
  const filtersMaxSize = width && width - REDUCED_WIDTH_FROM_FILTERS_LINE;

  const parsedFilters = useMemo(() => getActiveFiltersKeyValuePair(filters), [filters]);

  return (
    <div ref={ref} className={styles.findingsPage}>

      <div className={styles.header}>
        {uiShowResourcesPage && <RisksBreadcrumbs />}

        <div className={styles.topRow}>
          <PageTitles subtitle='pages.findings.subtitle' title='pages.findings.title' />

          <div className={styles.topRowRightSide}>
            <ExportFindingsToCsv />
          </div>
        </div>
      </div>

      <div className={styles.filtersTopRow}>
        <div className={styles.filtersWrapper}>
          <FindingsFilters filtersMaxSize={filtersMaxSize || FILTERS_MAX_SIZE_DEFAULT_SIZE} isFindingReadOnly={isFindingReadOnly} />
        </div>

        <SavedFiltersDropdown />
      </div>

      <div className={styles.tableWrapper}>
        <FindingsTable
          filters={parsedFilters}
          hasFetchedFilters={hasFetchedFilters}
          isLoading={uiShowSecurityChampionQuickGuide ? !isAnyScmVendorIntegrated || !hasFetchedFilters : !hasFetchedFilters}
        />
      </div>

    </div>
  );
};
